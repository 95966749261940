import React from 'react'
import { Chart } from "react-google-charts";
import { buildCalendarView } from '../insights_functions';

const InsightCalendarGraphView =(props)=>{
    const {viewData, activeOption} = props

    const currentOption = activeOption || viewData.DefaultOption;
    
    //console.log("layout response view", viewData);

    if(viewData && viewData.ViewData[currentOption] && viewData.ViewData[currentOption].ChartData)
    {
        buildCalendarView(viewData.ViewData[currentOption])
        return (
            <Chart
                chartType="Calendar"
                data={viewData.ViewData[currentOption].ChartData}
                options={{
                    title: viewData.Title,
                }}
                width="100%"
                height="100%"
            />
        )
    }

    return null;
}

export default InsightCalendarGraphView
