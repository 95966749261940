
import InsightBarGraphView from './components/InsightBarGraphView'
import InsightColumnGraphView from './components/InsightColumnGraphView'
import InsightLineGraphView from './components/InsightLineGraphView'
import InsightPieGraphView from './components/InsightPieGraphView'
import InsightGeoGraphView from './components/InsightGeoGraphView'
import InsightStatView from './components/InsightStatView'
import InsightViewStatMultiCol from './components/InsightViewStatMultiCol'
import InsightCalendarGraphView from './components/InsightCalendarGraphView'
import InsightProductionNoWorkTableView from './components/InsightProductionNoWorkTableView'

//Make sure this only contains insights related component
const colViewMap = {
    "vInsightViewBarGraph" : InsightBarGraphView,
    "vInsightViewColumnGraph" : InsightColumnGraphView,
    "vInsightViewLineGraph" : InsightLineGraphView,
    "vInsightViewPieGraph" : InsightPieGraphView,
    "vInsightViewGeoGraph" : InsightGeoGraphView,
    "vInsightViewStat" : InsightStatView,
    "vInsightViewStatMultiCol" : InsightViewStatMultiCol,
    "vInsightViewCalendar" : InsightCalendarGraphView,
    "vInsightProductionNoWorkViewTable" : InsightProductionNoWorkTableView

}

export default colViewMap;
