import * as fn from '../../../helper/uiFunctions';

const checkOptionAvailable = function (layoutData, keyToCheck) {

    if(!layoutData) return false;
    
    let optionKeys = Object.keys(layoutData);

    for (const optk of optionKeys) {
        if(optk === keyToCheck) return true;
    }
    
    return false;
}

const buildLayoutConfig = function (layoutString) {

    if(!layoutString) return {};
    
    const layoutObject = JSON.parse(layoutString);

    let layoutConfig = {};

    let configKeys = Object.keys(layoutObject);

    for (const ck of configKeys) {
        let configValues = Object.values(layoutObject[ck]);
        let configTransformed = [];

        for (var i = 0; i < configValues.length; i++) {
            configTransformed.push(
                {
                    i: configValues[i].INSIGHT_KEY, 
                    x: configValues[i].X_COORD, 
                    y: configValues[i].Y_COORD, 
                    w: configValues[i].WIDTH, 
                    h: configValues[i].HEIGHT
                });
        }

        layoutConfig[ck] = configTransformed;
    }
    
    // console.log("insight dashboard", layoutConfig);

    return layoutConfig;
}

const buildCalendarView = function (viewData) {

    if(!viewData) return {};

    var charData = viewData.ChartData

    console.log("calendar data before", viewData);

    for (var i = 1; i < charData.length; i++)
    {
        let dateString = charData[i][0];

        let parse = Date.parse(dateString);

        let date = new Date(parse);

        viewData.ChartData[i][0] = date;
    }

    console.log("calendar data after", viewData);
}

const buildProdNoWorkTableView = function (viewData) {

    if(!viewData) return {};

    var charData = viewData.ChartData

    console.log("table data before", viewData);

    for (var i = 1; i < charData.length; i++)
    {
        let data = charData[i][2];

        var formatedData; 

        if (data === -1) {
            formatedData = "Never Assigned";
        }
        else {
            formatedData = fn.convertMinutesToHHMM(data);
        }

        viewData.ChartData[i][2] = formatedData;
    }

    console.log("table data after", viewData);
}


export {buildLayoutConfig, checkOptionAvailable, buildCalendarView, buildProdNoWorkTableView};

