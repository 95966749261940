import React from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

const SarasDynamicGrid = (props) => {
    const { layoutConfig, children } = props;

    // const onLayoutChange = (e, v) => {
    //     console.log("layout changed", e, v);
    // }
    //add this prop to ResponsiveGridLayout
    // onLayoutChange={onLayoutChange} isDraggable={true}

    return(
        <ResponsiveGridLayout isResizable={false} isDraggable={false} className="" layouts={layoutConfig} 
            rowHeight={30}
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        >
            {children}
        </ResponsiveGridLayout>
    )
}

export default SarasDynamicGrid