import React, { useEffect } from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { acRequestData } from './acHelper'
import * as fn from "../../../helper/uiFunctions";

const useStyles = makeStyles(theme => ({
    label: {
      display: 'block',
    },
    input: {
      width: 200,
    },
    listbox: {
      width: 200,
      margin: 0,
      padding: 0,
      zIndex: 1,
      position: 'absolute',
      listStyle: 'none',
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      maxHeight: 200,
      border: '1px solid rgba(0,0,0,.25)',
      '& li[data-focus="true"]': {
        backgroundColor: '#4a8df6',
        color: 'white',
        cursor: 'pointer',
      },
      '& li:active': {
        backgroundColor: '#2977f5',
        color: 'white',
      },
    },
  }));

//TODO solve the problem of double trigger from auto complete in following scenario:
//key in some value, auto list will come, now instead of pressing tab select from the list.
//2 times event will fire 1st for onblur and 2nd on menu click
const SarasAutocomplete2 = (props) => {
    const { label, value, className, onChange, acMethod, acDepCtrl, acValidate, onGetDependantValue, rows, cols, acData, tabindex, autofocus } = props
    // const anchorRef = useRef(null);
    // const inputRef = useRef(null);

    const [open, setOpen] = React.useState(false);
    const [choicesList, setChoicesList] = React.useState([]);

    //this is updated on each key stroke for creating a controlled element
    const [lInputValue, setInputValue] = React.useState(label);

    //this will be updated only on selection
    const [acValue, setACValue] = React.useState({ key: value, text: label });
    const [showErr, setShowErr] = React.useState(false);

    //this will be set to false when user stats typing and will be set to true after selection is made
    //default is true to ensure events are not fired if user didn't key in any text
    const [isChoiceSelected, setIsChoiceSelected] = React.useState(true);

    const classes = useStyles();

    const onSearchFocus = (e) => {
        if (open) return;

        //console.log("onSearchFocus" ,lInputValue);

        //send a default request only if the input is blank
        if (inputProps.ref && !inputProps.ref.current.value && !acData) {
            acRequestData(getDependantValue(acDepCtrl), acMethodGet, "*", onRequestSuccess);
        }
        if (acData) {
            setOpen(true);
        }

        if (inputProps.ref && inputProps.ref.current && inputProps.ref.current.tagName !== 'TEXTAREA')
        {
            // console.log("test")
            inputProps.ref.current.select();
        }
        else{
            // console.log("test")
            inputProps.ref.current.selectionStart = inputProps.ref.current.selectionEnd = -1;
            inputProps.ref.current.setSelectionRange(0, 0);
        }
    }

    //scenarios
    //ensure on blur event is fired only if no selection was made after key strokes
    //do not activate the event if user steped in the box and then moved out without any key stroke
    //do not activate the event if user made the selection from menu and then moving out without any key stroke
    const onSearchFocusOut = (e) => {
        // console.log('autoComplete onSearchFocusOut', e);
        let val = inputProps.ref.current.value.trim();
        let valObj = null;
        //TODO fix the issue of double event, then remove false from the if condition
        if (!isChoiceSelected) {
            // console.log('autoComplete onSearchFocusOut', e.currentTarget.value);

            //input related scenarios
            if (val === "") {
                //1) no input provided so create an empty object to set blank to current value in the form
                valObj = { key: "", text: "" };
            }
            else {
                //get the first choice from the field list
                let keys = Object.keys(choicesList);

                if (keys.length === 0) {
                    //2) choice not available - raise an error (only if validate is enabled) and skip further action
                    if (!acValidate || !fn.compareNumber(acValidate, 0)) {
                        setShowErr(true);
                        valObj = { key: val, text: val };
                        if (onChange) onChange(e, valObj, 'key');
                        return;
                    }
                }
                //3) validation not needed, set the val to both key and text
                if (fn.compareNumber(acValidate, 0) && acValue.text !== val) {
                    valObj = { key: val, text: val };
                }
            }
            if (valObj) {
                setInputAndAcValue(e, valObj, 'key');
                setOpen(false);
            }

        }
        setOpen(false);
    }

    const onSearchChange = (e) => {
        if (isSelectedFromList) {
            isSelectedFromList = false;
            return;
        }
        //reset the choice made flag to ensure on blur event is fired to validate the selection
        setIsChoiceSelected(false);
        //reset show error if user is typing to ensure error is not displayed till focus moves out
        setShowErr(false);

        setInputValue(inputProps.ref.current.value); //e.currentTarget.value
        if (rows && cols) {
            inputProps.ref.current.value = e.currentTarget.value;
        }
        // setACValue(e.currentTarget.value);
        if (acData === undefined) {
            acRequestData(getDependantValue(acDepCtrl), acMethodGet, inputProps.ref.current.value, onRequestSuccess); //e.currentTarget.value
        }
        if (acData !== undefined) {
            setOpen(true);
        }
    }

    const handleSelection = (event) => {
        setIsChoiceSelected(true);
        inputProps.ref.current.focus();

        let fieldKey = event.currentTarget.getAttribute('fieldkey');
        let fieldvalue = event.currentTarget.getAttribute('fieldvalue');

        //trigger change event only if the value changes
        // if(fieldKey != acValue.key){
        // let fieldValue = event.currentTarget.innerText;
        const valObj = { key: fieldKey, text: fieldvalue };

        // console.log('autoComplete handleSelection', valObj);

        //setInputValue(fieldValue);
        if (rows && cols) {
            inputProps.ref.current.value = fieldvalue;
            valObj.key = valObj.text;
        }
        //setACValue(valObj);
        //if (onChange) onChange(event, valObj, 'menu');
        // }
        setInputAndAcValue(event, valObj, 'menu')
        isSelectedFromList = true;
        setOpen(false);
    }

    // const handleClose = (event) => {
    //     setOpen(false);
    // }

    const onKeyPress = (e) => {
        let val = e.currentTarget.value.trim();

        // console.log("key",e.key, inputValue)
        //if key press is tab and option list is open only then prevent Default tab actions and set first option from list
        if (e.key === "Tab" && open && val.length > 0) {
            e.preventDefault();
            e.stopPropagation();

            let valObj = {};

            if (choicesList.length === 0) {
                //2) choice not available - raise an error (only if validate is enabled) and skip further action
                if (!acValidate || fn.compareNumber(acValidate, 0)) {
                    valObj = { key: val, text: val };
                }
                else {
                    setShowErr(true);
                    return;
                }
            }
            else {
                let choice = choicesList[[0]];
                valObj = { key: choice.value, text: choice.label };
            }
            setInputAndAcValue(e, valObj, 'key')
            setOpen(false);
        }
        if(e.key === "Enter")
        {

        }
        // else if((e.key !== "ArrowDown" || e.key !== "ArrowUp") && open)
        //     setOpen(false);
    }

    const onRequestSuccess = (response) => {
        if (isSelectedFromList) {
            isSelectedFromList = false;
            return;
        }

        if (Array.isArray(response.data)) {
            setChoicesList(response.data);
            setOpen(true);
        }
    }

    const {
        getRootProps,
        // getInputLabelProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        inputValue
    } = useAutocomplete({
        open:open,
        options: choicesList,
        getOptionLabel: option => option.label,
    });
    //        onInputChange:onSearchChange,
    const {...inputProps} = getInputProps()

    const setInput = () => {
        if(inputValue !== lInputValue)
        {
            for(let i = 0 ; i < choicesList.length ; i++)
            {
                let choice = choicesList[i];
                if(inputValue === choice.label)
                {
                    let valObj = { key: choice.value, text: choice.label };
                    setInputAndAcValue(null,valObj)
                    setOpen(false);
                    break;
                }
            }
        }
    }
    React.useEffect(setInput,[inputValue])
    

    React.useEffect(() => {
        if (autofocus && inputProps.ref) {
            inputProps.ref.current.focus();
        }
    }, [autofocus, inputProps.ref]);
    let isSelectedFromList = false;

    const getDependantValue = (formula) => {
        let result = formula;
        //this will be availble only when the ac is called from form
        if (onGetDependantValue && formula) result = onGetDependantValue(result);
        //else return back the parameter as it is
        return result;
    }

    const acMethodGet = "Get" + getDependantValue(acMethod);

    console.log("acmethod", acMethodGet, acDepCtrl);

    const initializeCtrlValue = () => {
        if (value !== acValue.key) {
            let acKey = value;
            let acText = label;

            // console.log("value label", value, label);

            if (!acKey) acKey = '';
            if (!acText) acText = '';

            const valObj = { key: acKey, text: acText };
            setInputValue(acText);
            setACValue(valObj);
        }
    }
    useEffect(initializeCtrlValue, [value, label]);

    const initializeAcData = () => {
        if (acData !== undefined && acData != null) {
            setChoicesList(acData)
        }
    }
    useEffect(initializeAcData, [acData]);

    //useAutocomplete hook
    
    const setInputAndAcValue = (event, valObj, source) => {
        setInputValue(valObj.text);
        setACValue(valObj);
        if (onChange) onChange(event, valObj, source);
    }

    const renderInput = () => {
        // console.log("useEffect", autofocus)
        //ref={inputProps.ref}
        if(!getInputProps) return(null)
        if (rows && cols) {
            // console.log("autocomplete textArea=: ", lInputValue)
            return (
                <textArea {...inputProps}  onBlur={onSearchFocusOut} onChange={onSearchChange} onFocus={onSearchFocus} className={className} rows={rows} cols={cols} tabindex={tabindex}
                    style={{ backgroundColor: showErr ? '#ff7961' : '#c6f7bb', ...props.style }} placeholder={props.placeholder} autoComplete="off" value={lInputValue} >{lInputValue}
                </textArea>
            )
        }
        else {
            return (
                <input {...inputProps} type="text" onBlur={onSearchFocusOut} onChange={onSearchChange} onFocus={onSearchFocus}
                    onKeyDown={onKeyPress} className={className} tabindex={tabindex} placeholder={props.placeholder} autoComplete="off"
                    style={{ backgroundColor: showErr ? '#ff7961' : '#c6f7bb', ...props.style }}  value={lInputValue}
                ></input>
            )
        }
    }

    return (
        <>
            <div style={{ display: "inline-block" }} >
                <div {...getRootProps()}>
                    {renderInput()}
                    {/* <input {...getInputProps()} onInputChange={inputChange} onChange={onSearchChange} 
                    value={lInputValue}
                    style={{ backgroundColor: showErr ? '#ff7961' : '#c6f7bb', ...props.style }}
                    /> */}
                </div>
                {groupedOptions.length > 0 ? (
                    <ul className={classes.listbox} style={{minWidth:props.style && props.style.width ? props.style.width : 200}} {...getListboxProps()}>
                        {groupedOptions.map((option, index) => (
                            <li className="ui-menu-item" {...getOptionProps({ option, index })} fieldvalue={option.label} fieldkey={option.value} onClick={handleSelection} >
                                {option.label}
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
        </>
    )
}

export default SarasAutocomplete2