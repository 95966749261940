
//Make sure this only contains insights related component
const shade200 = [
    '#0277BD', //light blue
    '#2E7D32', //green
    '#4527A0', //deep purple
    '#D84315', //deep orange
    '#9E9D24', //lime
    '#00695C', //teal
    '#F9A825', //yellow
    '#FF8F00', //amber
    '#1565C0', //blue
    'C62828', //red
    'AD1457', //pink
    '6A1B9A', //purple
    '283593', //indigo
    '00838F', //cyan
    '558B2F', //light green
    'EF6C00', //orange
    '4E342E', //brown
    '424242', //gray
    '37474F', //blue gray
];

const blueFamily = ['#01579B', '0277BD', '0288D1', '039BE5', '03A9F4', '29B6F6', '4FC3F7', '81D4FA', 'B3E5FC', '#E1F5FE'];

const shade800 = [
    '#0277BD', //light blue
    '#2E7D32', //green
    '#4527A0', //deep purple
    '#D84315', //deep orange
    '#9E9D24', //lime
    '#00695C', //teal
    '#FF8F00', //amber
    '#1565C0', //blue
    'C62828', //red
    'AD1457', //pink
    '6A1B9A', //purple
    '283593', //indigo
    '00838F', //cyan
    'EF6C00', //orange
    '4E342E', //brown
    '424242', //gray
    '37474F', //blue gray
    '558B2F', //light green
    '#F9A825', //yellow
];

const shadeDefault = shade800;

export {shade200, shade800, blueFamily, shadeDefault};
