import React from 'react'
import { Chart } from "react-google-charts";
import { buildProdNoWorkTableView } from '../insights_functions';

const InsightProductionNoWorkTableView =(props)=>{
    const {viewData, activeOption} = props

    const currentOption = activeOption;// || viewData.DefaultOption;
    
    
    if(viewData && viewData.ViewData[currentOption] && viewData.ViewData[currentOption].ChartData)
    {
        console.log("layout response view table", viewData.ViewData[currentOption].ChartData);

        buildProdNoWorkTableView(viewData.ViewData[currentOption])

        console.log("layout response view table2", viewData.ViewData[currentOption].ChartData);

        return (
            <Chart
                chartType="Table"
                data={viewData.ViewData[currentOption].ChartData}
                options={{
                    title: viewData.Title,
                    allowHtml: true,
                    showRowNumber: true,
                    legend: { position: "bottom" },
                    pageSize: 10,
                }}
                formatters={
                    [{
                        type: "ColorFormat",
                        column: 2,
                        options: {
                          width: 120,
                        },
                        ranges: [
                          [null, 0, "red", "white"],
                          ['01:00', null, "red", "white"],
                        ],
                      },
                    ]
                }
                width="100%"
                height="100%"
            />
        )
    }

    return null;
}

export default InsightProductionNoWorkTableView
