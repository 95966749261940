import React from 'react'
import {  } from "react-google-charts";

const InsightStatView =(props)=>{
    const {viewData, activeOption} = props

    const currentOption = activeOption;// || viewData.DefaultOption;

    //var data = viewData.ViewData[currentOption].ChartData[1];
    
    if(viewData && viewData.ViewData[currentOption] && viewData.ViewData[currentOption].ChartData
     && viewData.ViewData[currentOption].ChartData.length >= 2
    ){
        
        // console.log("layout response view", viewData.ViewData[currentOption]);
    
        return (
            <div className='uiInsightStatData uiPadding10'>
                <div>
                    <span className='uiBold' style={{fontSize: '20px'}}>{viewData.X_AXIS_Title}</span>
                </div>
                <div >
                    <span className='uiBold' style={{fontSize: '18px', color:'#0277BD'}}>{viewData.ViewData[currentOption].ChartData[1][1]}</span> 
                    <span className='uiSpacerL20' style={{fontSize: '14px'}}>{viewData.ViewData[currentOption].Y_AXIS_Title}</span>
                </div>
            </div>
        )

    }

    return null;
}

export default InsightStatView
