import React from 'react'
import { Chart } from "react-google-charts";
import { shadeDefault } from '../InsightsColors';

const InsightGeoGraphView =(props)=>{
    const {viewData, activeOption, mapRegionKey} = props

    const currentOption = activeOption;// || viewData.DefaultOption;
    
    // console.log("layout response view", viewData.ViewData[currentOption].ChartData);

    if(viewData && viewData.ViewData[currentOption] && viewData.ViewData[currentOption].ChartData)
        return (
            <Chart
                chartType="GeoChart"
                data={viewData.ViewData[currentOption].ChartData}
                options={{
                    title: viewData.Title,
                    bar: { groupWidth: "75%" },
                    legend: { position: "bottom" },
                    hAxis: { title: viewData.X_AXIS_Title },
                    vAxis: { title: viewData.ViewData[currentOption].Y_AXIS_Title },
                    colors: shadeDefault,
                    region: mapRegionKey || 'world'
                }}
                width="100%"
                height="100%"
            />
        )

    return null;
}

export default InsightGeoGraphView
