import React, { useState } from 'react';
import SarasTrans from '../../../library/notifications/SarasTrans';
import DocSearch from "../../cmn/docSearch/components/DocSearch";
import ActionButton from '../../../library/input/buttons/button/SarasButton2';

const InsightsCriteria = (props) => {
    const { viewData, report, onCriteriaApply, onCriteriaClose } = props;
    const [insightCriteria, setInsightCriteria] = useState(null);

    // console.log("criteriaValue", viewData);

    const onChange = (reload, criteriaValue) => {
        // console.log("criteriaValue", criteriaValue);
        
        setInsightCriteria(criteriaValue);
    }
    
    const onApplyClicked = () => {

        if(onCriteriaApply) onCriteriaApply(insightCriteria);
    }

    const onCancelClicked = () => {

        if(onCriteriaClose) onCriteriaClose();
    }

    if (!viewData)
        return(<div>hi</div>);

    if(Object.values(viewData.childViews).length <= 0)
        return(<div>hi</div>);

    return (
        <div className="uiInsightView uiInsightBox">
            <div className="uiInsightViewAction">
                <div>
                    <div className='uiInsightViewActionLeft'>
                        <span className='uiInsightCriteriaEditor'>
                            <SarasTrans value="Edit Criteria" />
                        </span>
                    </div>
                    <div className='uiInsightViewActionRight'>
                        <ActionButton onClick={onApplyClicked} text="Apply Criteria" icon="applyChanges" 
                                tooltip="Apply new criteria"
                                showText={1}
                                className="uiHoverRound uiLink uiSpacerH5"/>
                        <ActionButton onClick={onCancelClicked} text="Cancel Criteria" icon="cancelChanges" 
                                tooltip="Cancel changing criteria"
                                showText={1}
                                className="uiHoverRound uiLink uiSpacerH5"/>
                    </div>
                </div>
            </div>

            <div className="uiInsightViewContent">
                <div className='uiInsightCriteriaEditor'>
                    <DocSearch viewData={viewData} onSearchByCriteria={onChange} rptKey={report} actionName="coreMIS" 
                        enableSearch={false} enableGroups={false} enablefav={false} 
                    />
                </div>
            </div>
        </div>
    )
}

export default InsightsCriteria
